<template >
  <div>
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="closeSidebar()"
        />
      </div>
    </div>
    <b-card-code title="Assigned">
      <validation-observer ref="BulkMitigateForm">
        <form ref="form" @submit.prevent="bulkAssignTasks">
          <!-- Owner-->
          <b-form-group label="Assign To" label-for="Assign to:">
            <template #label>
              <span>Assign To</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="task_assignee"
              rules="required"
            >
              <b-form-select
                v-model="task_assignee"
                :options="users"
                :state="errors.length > 0 ? false : null"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Task Priority-->
          <b-form-group label="Priority" label-for="Priority">
            <template #label>
              <span>Priority</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="task_priority"
              rules="required"
            >
              <b-form-select
                v-model="task_priority"
                :options="task_priority_options"
                :state="errors.length > 0 ? false : null"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Task Priority-->

          <b-form-group label="Due Date" label-for="Priority">
            <template #label>
              <span>Due Date</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="Due date"
              rules="required"
            >
              <flat-pickr
                placeholder="Due Date"
                style="width: 100%"
                v-model="due_date"
                class="form-control bg-background"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- <b-button type="submit" variant="primary" :disabled="invalid" size="sm">
            Assign
          </b-button> -->
          <b-button
            type="submit"
            variant="primary"
            :disabled="
              isdisable ||
              due_date == null ||
              task_assignee == '' ||
              task_priority == null
            "
            size="sm"
          >
            <span v-if="isloading"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else> Assign Task</span>
          </b-button>
        </form>
      </validation-observer>
    </b-card-code>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    flatPickr,
  },
  props: {
    organization: {
      type: Number,
      required: true,
    },
    vulnerabilties: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    callBack: {
      type: Function,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
    isCheckAllFilterResults: {
      type: Boolean,
      required: true,
    },
    filter: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      //Add Task Modal
      task_assignee: "",
      task_priority: null,
      task_priority_options: [
        { value: null, text: "--Select Priority--" },
        { value: 1, text: "Low" },
        { value: 2, text: "Medium" },
        { value: 3, text: "High" },
        { value: 4, text: "Critical" },
      ],
      isloading: false,
      isdisable: false,
      due_date: null,
    };
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      this.task_title = this.task;
      this.task_description = this.description;
    },
    bulkAssignTasks: function () {
      this.isloading = true;
      this.isdisable = true;
      let data = {
        task_assignor: localStorage.getItem("userid"),
        task_assignee: this.task_assignee,
        task_type: "VULNERABILITY",
        task_priority: this.task_priority,
        vulnerabilties: this.vulnerabilties,
        due_date: this.due_date,
      };
      if (this.isCheckAllFilterResults && this.filter.length > 0) {
        data.filters = this.filter;
      }
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "task/task/bulk-assign/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Successfully added the task",
              icon: "EditIcon",
              variant: "success",
            },
          });
          // this.changeStatus(5);
          this.callBack();
          this.closeSidebar();
        }
        this.isloading = false;
        this.isdisable = false;
      });
    },
    // changeStatus: function (status) {
    //   var self = this;
    //   this.loading = true;
    //   let data = {
    //     status: status,
    //     vulnerabilties: this.vulnerabilties,
    //   };
    //   if (this.isCheckAllFilterResults && this.is_search_on) {
    //     data.filters = this.filter;
    //   }
    //   this.$http({
    //     method: "POST",
    //     data: data,
    //     headers: { "content-type": "application/json" },
    //     url:
    //       process.env.VUE_APP_BASEURL +
    //       "asset/vulns/update-vulnerability-in-bulk/",
    //   }).then((res) => {
    //     if (res.data.status == "success") {
    //       self.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: "Vulnerability status updated successfully",
    //           // title: res.data.message,
    //           icon: "EditIcon",
    //           variant: "success",
    //         },
    //       });
    //       this.vulnerabilties = [];
    //       // self.searchFn(false);
    //     } else {
    //       self.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: res.data.message,
    //           icon: "EditIcon",
    //           variant: "danger",
    //         },
    //       });
    //     }
    //     this.loading = false;
    //   });
    // },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.bg-background {
  background: #fff !important;
}
[dir] .dark-layout .bg-background {
  // background: #283046 !important;
  background: #253e42 !important;
  border-color: #404656;
}
</style>